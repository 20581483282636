import { redirectPageName } from "../utils/helpers";

export default async function guest({ next, store }) {
  await store.dispatch("auth/changeUserState");

  if (store.getters["auth/loggedIn"]) {
    return next({
      name: redirectPageName(),
    });
  }

  return next();
}
