import { extend } from "vee-validate";
import {
  required,
  email,
  digits,
  numeric,
  regex,
  alpha_num,
  min,
  max,
} from "vee-validate/dist/rules";

// Install rules
extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

extend("confirmed", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Passwords do not match",
});
extend("numeric", {
  ...numeric,
  message: "{_field_} must be numeric",
});

extend("decimal", {
  params: ["target"],
  validate(value, { target }) {
    if (isNaN(value)) {
      return false;
    }
    const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,<>/?]+/;
    if (format.test(value)) {
      return false;
    }
    const decimalValue = value?.split(".")[1]?.length;
    if (decimalValue === undefined) {
      return true;
    }
    return decimalValue <= target;
  },
  message: "{_field_} must be numeric and may contain {target} decimal points",
});
extend("digits", {
  ...digits,
  params: ["target"],
  validate(value, { target }) {
    return value.length === Number(target);
  },
  message: "{_field_} must be numeric and of size {target}",
});

extend("regex", regex);
extend("alpha_num", {
  ...alpha_num,
  message: "{_field_} must contain alpha numeric values",
});

extend("min", {
  ...min,
  params: ["target"],
  validate(value, { target }) {
    return value.length >= Number(target);
  },
  message: "{_field_} must be of minimum size {target}",
});
extend("max", {
  ...max,
  params: ["target"],
  validate(value, { target }) {
    return value.length <= Number(target);
  },
  message: "{_field_} must be of maximum size {target}",
});
extend("password", {
  validate(value) {
    const format = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (!format.test(value) || value.length < 8) {
      return false;
    }
    return true;
  },
  message: "{_field_} must have 1 special character and minimum length of 8",
});
