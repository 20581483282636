import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import notifications from "./modules/notifications";
import notificationsMessage from "./modules/notificationMessage";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isUserEddited: false,
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    notifications,
    notificationsMessage,
  },
});
