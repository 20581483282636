import { redirectPageName } from "../utils/helpers";

export default async function authAccess({ next, store, menuName }) {
  await store.dispatch("auth/changeUserState");
  let arrayOfAccess = store.state.auth.userState.actionItems
    ? store.state.auth.userState.actionItems.map((item) => {
        return item.parentSection;
      })
    : [];
  //   console.log(menuName);

  if (!arrayOfAccess.includes(menuName)) {
    return next({
      name: redirectPageName(),
    });
  }

  return next();
}
