import store from "../../store";
import SecureStorage from "secure-web-storage";
// import moment from "moment";

const redirectPageName = () => {
  const isLoggedIn = store.getters["auth/loggedIn"];
  const role = store.state.auth.userState.roleName;
  if (isLoggedIn && role === "Admin") {
    return "Events";
  }
  if (isLoggedIn && role === "Agent") {
    return "Events";
  }
  if (isLoggedIn && role === "Coordinator") {
    return "Events";
  }
  if (isLoggedIn && role === "Auditor") {
    return "Events";
  }
  if (!isLoggedIn) {
    return "Login";
  }

  return "Login";
};
const menuList = (role) => {
  return [
    {
      menuName: "Event",
      icon: "mdi-calendar-clock",
      link: "/events",
      subLink: "/events/addupdate",
    },
    {
      menuName: "User",
      icon: "mdi-account",
      link: "/users",
    },

    {
      menuName: "Hotel",
      icon: "mdi-office-building",
      link: "/hotels",
    },
    { menuName: "Gift", icon: "mdi-gift", link: "/gifts" },
    {
      menuName: "Activity",
      icon: "mdi-text-box-check",
      link: "/activities",
    },
    {
      menuName: "Itinerary",
      icon: "mdi-map-marker-path",
      link: "/itinerary",
    },

    {
      menuName: "UserAccess",
      icon: "mdi-account-wrench",
      link: "/user-access",
    },
    {
      menuName: "AttendeesManagement",
      icon: "mdi-account-group",
      link: "/attendees",
    },
    {
      menuName: "EventRegisteredReport",
      link: "/reports",
      icon: "mdi-google-spreadsheet",
    },
  ].filter((item) => role.includes(item.menuName));
};
const toMMDDYYYY = (d) => {
  const date = d.split(" ")[0].split("-");
  return `${date[0]}/${date[1]}/${date[2]}`;
};
const checkFormatType = (value, size, type) => {
  let allowedExtension = [];
  if (type === "image") {
    allowedExtension = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
      "image/bmp",
    ];
  } else if (type === "pdf") {
    allowedExtension = ["application/pdf"];
  } else if (type === "video") {
    allowedExtension = [
      "video/mpeg",
      "video/mpg",
      "video/avi",
      "video/wmv",
      "video/mov",
      "video/rm",
      "video/ram",
      "video/swf",
      "video/flv",
      "video/ogg",
      "video/webm",
      "video/mp4",
    ];
  }

  if (allowedExtension.indexOf(value) > -1 && size <= 5242880) {
    return "ok";
  } else {
    alert(
      "Please Select Correct file format and file size should be less then 5 mb"
    );
  }
};
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const actionEnable = (parent, child, access) => {
  return store.state.auth.userState.actionItems
    ? store.state.auth.userState.actionItems.some((item) => {
        return (
          item.parentSection === parent &&
          item.childSection === child &&
          item.accessModeId === access
        );
      })
    : "";
};
// const dateFormatMMDDYYYY = (val) => {
//   return val ? moment(val).format("MM-DD-YYYY") : "";
// };
const dateFormat = (inputDate, format) => {
  const date = new Date(inputDate);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  format = format.replace("MM", month.toString().padStart(2, "0"));

  if (format.indexOf("yyyy") > -1) {
    format = format.replace("yyyy", year.toString());
  } else if (format.indexOf("yy") > -1) {
    format = format.replace("yy", year.toString().substr(2, 2));
  }

  //replace the day
  format = format.replace("dd", day.toString().padStart(2, "0"));

  return format;
};
// const dateFormatYYYMMDD = (val) => {
//   return val ? moment(val).format("YYYY-MM-DD") : "";
// };
const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    time = time.slice(1);
    time[5] = +time[0] < 12 ? " AM" : " PM";

    time[0] = +time[0] % 12 || 12;
    time[0] = time[0] < 10 ? "0" + time[0] : time[0];
  }
  return time.join("");
};
// const URLtoImage=() => {
//   const response = await fetch(imageUrl)
//   const imageBlob = await response.blob()
//   const reader = new FileReader();
//   reader.readAsDataURL(imageBlob);
//   reader.onloadend = () => {
//     const base64data = reader.result;
//     console.log(base64data);
//   }
// }
const convertInto12Hr = (timeStr) => {
  const [time, modifier] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};
const getDateFormat = (date) => {
  return (
    date.split(" ")[1] + ":" + date.split(" ")[2] + " " + date.split(" ")[3]
  );
};
const phoneMasking = (value) => {
  value
    .replace(/\D+/g, "")
    .replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, "$1-$2-$3");
};
const byteToFileReader = (value, fileName, type) => {
  var blob = new Blob([value], {
    type:
      type === "xlsx"
        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        : type === "image"
        ? "image/*"
        : type === "video"
        ? "video/*"
        : "application/pdf",
  });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};
const secureStorage = () => {
  var CryptoJS = require("crypto-js");

  var SECRET_KEY = "SRLIFE-PWA";
  return new SecureStorage(localStorage, {
    hash: function hash(key) {
      // key = CryptoJS.SHA256(key, SECRET_KEY);

      return key.toString();
    },
    encrypt: function encrypt(data) {
      data = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY);

      data = data.toString();

      return data;
    },
    decrypt: function decrypt(data) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);

      data = data.toString(CryptoJS.enc.Utf8);

      return JSON.parse(data);
    },
  });
};
const removeToken = () => {
  // secureStorage().removeItem("ConnectionKey");
  secureStorage().removeItem("token");
  secureStorage().removeItem("userId");
  secureStorage().removeItem("userName");
  secureStorage().removeItem("roleId");
  secureStorage().removeItem("roleName");
  secureStorage().removeItem("email");
  secureStorage().removeItem("address");
  secureStorage().removeItem("phone");
  secureStorage().removeItem("active");
  secureStorage().removeItem("refreshToken");
  secureStorage().removeItem("expiryTime");
  secureStorage().removeItem("notificationCount");
  secureStorage().removeItem("actionItems");
  secureStorage().removeItem("User");
  secureStorage().removeItem("setMessage");
};

export {
  checkFormatType,
  redirectPageName,
  menuList,
  toMMDDYYYY,
  toBase64,
  secureStorage,
  dateFormat,
  tConvert,
  convertInto12Hr,
  getDateFormat,
  phoneMasking,
  byteToFileReader,
  actionEnable,
  removeToken,
};
