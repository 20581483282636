import http from "./http-client";

class EventService {
  getEventDetail(payload) {
    return http.get(`/Event/Details?eventId=${payload}`);
  }
  updateEvent(payload) {
    return http.put("/Event/Update", payload);
  }
  eventList(payload) {
    return http.get("/Event/List", payload);
  }
  addEvent(payload) {
    return http.post("/Event/Add", payload);
  }
  uploadMedia(payload) {
    return http.post("/Event/uploadMedia", payload);
  }
  deleteMedia(payload) {
    return http.delete("/Event/DeleteMedia/", {
      data: payload,
    });
  }
  deleteEvent(payload) {
    return http.delete(`/Event/DeleteEvent?eventId=${payload}`);
  }
  subscribe(payload) {
    return http.post("/EventAttendee/ApplyEvent", payload);
  }
  giftByEvent(payload) {
    return http.get(`/Home/GiftListByEvent?eventId=${payload}`);
  }
  generateAccessCode(payload) {
    return http.post("/EventAttendee/GenerateAccessCode", payload);
  }
  validateAccessCode(payload) {
    return http.post("/EventAttendee/ValidateAccessCode", payload);
  }
  importQualifiedAttendees(payload) {
    return http.post("/Event/ImportQualifiedAgents", payload);
  }
  getEventStatus(payload) {
    return http.put("Event/UpdateEventStatus", payload);
  }
  getEventAttendeesAndNonAttendees(payload) {
    return http.post(
      `AttendeesManagement/GetSubscribeUnsubscribeRequestList?EventId=${payload}`
    );
  }
  getActiveEvents() {
    return http.get("/AttendeesManagement/ActiveEventList");
  }
  subscribeOrUnsubscribe(payload) {
    return http.post(`/Event/RequestSubscribeUnsubscribe`, payload);
  }
  updateToSubscribed(payload) {
    return http.post(`/AttendeesManagement/UpdateAttendees`, payload);
  }
  downloadGuestSpeakerMedia(payload) {
    return http.post(`/Event/DownloadGuestSpeakerMedia`, payload, {
      responseType: "blob",
    });
  }
  pushNotificationService(payload) {
    // console.log("Hello");
    // const msg = "This is push message from frontend";
    return http.post(`/Notification/SendMessage`, payload);
  }
  getEventAttendeesForNotification(id) {
    // console.log("Hello");
    // const msg = "This is push message from frontend";
    return http.get(`/Notification/GetEventAttendees?eventId=${id}`);
  }
  getNotifications() {
    return http.get(`/Home/GetNotifications`);
  }
  updateNotificationId(payload) {
    return http.put(
      `/Home/UpdateEvetAttendeeNotification?attendeeNotificationId=${payload.attendeeNotificationId}&isRead=${payload.isRead}`
    );
  }
  updateConnection(payload) {
    return http.put(`/Home/UpdateConnection`, payload);
  }
}

export default new EventService();
