import Vue from "vue";
import VueRouter from "vue-router";

// Import the layout files
// import Dash from "@/components/Dash.vue";

// Import the middleware functions
import { authAccess, auth, guest, middlewarePipeline } from "../middleware";

// Import the store
import store from "../store";
let titleName;

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: { title: "Login", middleware: [guest] },
  },
  {
    path: "/login/reset-password",
    name: "ResetPassword",

    component: () =>
      import(
        /* webpackChunkName: "Reset Password" */ "../views/ResetPassword.vue"
      ),
    meta: { title: "Reset Password", middleware: [guest] },
  },
  {
    path: "/hotels",
    name: "HotelManagement",
    component: () =>
      import(/* webpackChunkName: "Hotels" */ "../views/Hotels.vue"),
    meta: {
      title: "Hotel ",
      layout: "MasterLayout",
      middleware: [auth, authAccess],
      menuName: "Hotel",
    },
  },
  {
    path: "/users",
    name: "userManagement",
    component: () =>
      import(/* webpackChunkName: "Users" */ "../views/Users.vue"),
    meta: {
      title: "User ",
      layout: "MasterLayout",
      middleware: [auth, authAccess],
      menuName: "User",
    },
  },
  {
    path: "/itinerary",
    name: "itineraryManagement",
    component: () =>
      import(/* webpackChunkName: "Users" */ "../views/Itinerary.vue"),
    meta: {
      title: "Itinerary ",
      layout: "MasterLayout",
      middleware: [auth, authAccess],
      menuName: "Itinerary",
    },
  },
  {
    path: "/activities",
    name: "Activities",
    component: () =>
      import(/* webpackChunkName: "Activity" */ "../views/Activities.vue"),
    meta: {
      title: "Activities ",
      layout: "MasterLayout",
      middleware: [auth, authAccess],
      menuName: "Activity",
    },
  },
  {
    path: "/gifts",
    name: "Gifts",
    component: () =>
      import(/* webpackChunkName: "Gifts" */ "../views/Gifts.vue"),
    meta: {
      title: "Gifts",
      layout: "MasterLayout",
      middleware: [auth, authAccess],
      menuName: "Gift",
    },
  },

  {
    path: "/events",
    name: "Events",
    component: () =>
      import(/* webpackChunkName: "events/list" */ "../views/events/List.vue"),
    meta: {
      title: "Events",
      middleware: [auth, authAccess],
      layout: "MasterLayout",
      menuName: "Event",
    },
  },
  {
    path: "/reports",
    name: "Reports",
    component: () =>
      import(/* webpackChunkName: "Reports" */ "../views/Reports.vue"),
    meta: {
      title: "Event Registered Report",
      middleware: [auth, authAccess],
      layout: "MasterLayout",
      menuName: "EventRegisteredReport",
    },
  },
  {
    path: "/user-access",
    name: "UserAccess",
    component: () =>
      import(/* webpackChunkName: "Roles" */ "../views/Roles.vue"),
    meta: {
      title: "User Access",
      middleware: [auth, authAccess],
      layout: "MasterLayout",
      menuName: "UserAccess",
    },
  },
  {
    path: "/events/addupdate",
    name: "AddEvent",
    component: () =>
      import(/* webpackChunkName: "add-events" */ "../views/events/Add.vue"),
    //meta: { title: "Add Event", middleware: [guest] },
    meta: {
      title: titleName,
      layout: "MasterLayout",
      middleware: [auth, authAccess],
      menuName: "Event",
    },
  },
  {
    path: "/events/detail",
    name: "EventDetails",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/events/Details.vue"),
    meta: {
      title: "Event Info",
      layout: "MasterLayout",
      middleware: [auth, authAccess],
      menuName: "Event",
    },
  },
  {
    path: "/attendees",
    name: "Attendees",
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/Attendees.vue"),
    meta: {
      title: "Attendees Management",
      layout: "MasterLayout",
      middleware: [auth, authAccess],
      menuName: "Event",
    },
  },
  {
    path: "/404",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFoundPage.vue"),
  },
  {
    path: "*",
    redirect: { name: "NotFound" },
  },
];

const router = new VueRouter({
  routes,

  // mode: 'history',
  // linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0 });
        }, 10);
      });
    }
  },
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Events App";

  if (to.name === "AddEvent" && to.query.length == 0) {
    titleName = "Add Event";
  } else {
    titleName = "Update Event";
  }
  // console.log(to.meta.middlewar);
  if (!to.meta.middleware) {
    return next();
  }

  const middleware = to.meta.middleware;
  const menuName = to.meta.menuName;
  const context = {
    to,
    from,
    next,
    store,
    menuName,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
