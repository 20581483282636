<template>
  <v-app app>
    <notifications-bar></notifications-bar>
    <notification-alert
      :EventTitle="eventTitle"
      :messageTitle="title"
      :message="message"
      :dialog="isNotification"
      @close="
        closeNotifcation($event);
        isNotification = $event.status;
      "
    />
    <v-main class="pt-0">
      <!-- <component :is="layout"> -->
      <router-view :key="$route.fullPath" />
      <!-- </component> -->
    </v-main>
  </v-app>
</template>

<script>
import NotificationsBar from "@/components/NotificationsBar.vue";
import NotificationAlert from "./components/Modals/NotificationAlert.vue";
import { secureStorage } from "./utils/helpers";
import EventService from "./services/EventService";
const signalR = require("@microsoft/signalr");
export default {
  name: "App",
  data() {
    return {
      connection: null,
      isNotification: false,
      title: "",
      message: "",
      keys: "",
      eventTitle: "",
    };
  },
  components: {
    NotificationsBar,
    NotificationAlert,
  },

  computed: {
    layout() {
      return `${this.$route.meta.layout || "DefaultLayout"}`;
    },
  },
  async created() {
    this.keys = secureStorage().getItem("ConnectionKey");
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.showUpdateUI = true;
      });
    }
    {
      let connection = new signalR.HubConnectionBuilder()
        .withUrl(`${process.env.VUE_APP_API_BASE_URL}/events`)
        // .withUrl(`/events`)
        // ${process.env.VUE_APP_API_BASE_URL}
        .build();
      connection.on("sendToUser", (data) => {
        if (data) {
          this.isNotification = true;
          this.eventTitle = data.split("$%")[0];
          this.title = data.split("$%")[1];
          this.message = data.split("$%")[2];
          this.$store.dispatch("notificationsMessage/getNotificationMessage");
        }
      });

      connection.start().then(() => {
        setTimeout(() => {
          let key = connection.connection?.connectionId;
          console.log(this.keys);
          if (this.keys) {
            const res = EventService.updateConnection({
              oldConnectionId: this.keys,
              newConnectionId: key,
            });
            if (res) {
              this.$store.dispatch(
                "notificationsMessage/getNotificationMessage"
              );
            }
          } else {
            this.$store.commit("auth/addConnectionKey", key);
          }
          secureStorage().setItem("ConnectionKey", key);
          // if()
        });
      }, 500);
    }
  },
  async accept() {
    this.showUpdateUI = false;
    await this.$workbox.messageSW({ type: "SKIP_WAITING" });
  },
  methods: {
    closeNotifcation() {},
  },
};
</script>
