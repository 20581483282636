import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

Vue.component("font-awesome-icon", FontAwesomeIcon); // Register component globally
library.add(fas); // Include needed icons

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    themes: {
      light: {
        primary: "#f0ce6a",
        secondary: "#f3db91",
        accent: "#262626",
        error: "#b71c1c",
        dark: "#181818",
      },
    },
  },
});
