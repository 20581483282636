import { secureStorage } from "../utils/helpers";
import http from "./http-client";

class AuthService {
  login(payload) {
    return http.post("/login", payload);
  }
  logout() {
    let payload = {
      connectionId: secureStorage().getItem("ConnectionKey")
        ? secureStorage().getItem("ConnectionKey")
        : "",
    };
    return http.post("/logout", payload);
  }
  forgotPassword(payload) {
    return http.post("/ForgotPassword", payload);
  }
  resetPassword(payload) {
    return http.post("/ResetPassword", payload);
  }
  // resisterAdmin(payload) {
  //   return http.post("/resister-admin", payload);
  // }
}

export default new AuthService();
