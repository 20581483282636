<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    content-class="alertModal"
  >
    <v-card>
      <v-card-title primary-title>{{ EventTitle }}</v-card-title>
      <v-card-text>
        <div style="display: flex">
          <div><v-icon style="font-size: 400%">mdi-forum</v-icon></div>
          <div class="pl-5">
            <strong style="font-size: 15px">{{ messageTitle }}</strong>
            <p style="text-align: justify">
              {{ message }}
            </p>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close', { status: false })"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  mounted() {},
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
    },
    messageTitle: {
      type: String,
    },
    EventTitle: {
      type: String,
    },
  },
  data: () => ({
    id: 0,
    name: "",
    status: false,
    actionBy: 1,
    loading: false,
  }),
};
</script>
