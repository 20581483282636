<template>
  <div class="dashboard-cover">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <v-list-item>
          <v-list-item-content>
            <!-- <v-list-item-title class="text-h6"> -->
            <img
              src="../assets/images/Full-Logo-Gold.png"
              alt="logo"
              width="150px"
              class="mx-auto"
            />
            <!-- </v-list-item-title> -->
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense nav>
          <v-list-item
            link
            v-for="item in menus"
            :key="item.menuName"
            :style="
              item.link === routeLink || item.subLink === routeLink
                ? 'background: #efce6a;border-radius:0px'
                : ''
            "
          >
            <v-list-item-icon>
              <v-icon dark>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                @click="$router.push({ path: item.link }).catch(() => {})"
                >{{
                  item.menuName === "EventRegisteredReport"
                    ? "Registered Report"
                    : item.menuName === "AttendeesManagement"
                    ? "Attendees Request"
                    : item.menuName
                }}
              </v-list-item-title>
            </v-list-item-content>

            <!--  -->
          </v-list-item>
          <!-- <v-list-item link>
            <v-list-item-icon>
              <v-icon dark>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                @click="$router.push({ path: '/attendees' }).catch(() => {})"
                >Attendees</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item link v-if="checkSendMessage">
            <v-list-item-icon>
              <v-icon dark>mdi-send</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title @click="sendNotification"
                >Send Notifications</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item link>
            <v-list-item-icon>
              <v-icon dark>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title @click="logout()">Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app class="header-top">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div style="margin-right: 5px">
          <template>
            <div class="text-center">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    elevation="0"
                    dark
                    rounded
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-badge
                      color="red"
                      v-if="countIsNotRead !== 0"
                      :content="countIsNotRead"
                    >
                      <v-icon dark>mdi-bell </v-icon>
                    </v-badge>
                    <v-icon v-else dark>mdi-bell </v-icon>
                  </v-btn>
                </template>
                <v-menu transition="slide-x-transition" bottom left offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list style="height: 200px; overflow-y: scroll">
                      <div
                        v-for="(notification, index) in notifications"
                        :key="index"
                      >
                        <v-list-item
                          link
                          @click="readNotification(notification)"
                          @mouseover="messageHover(notification)"
                          :style="
                            !notification.isRead ? 'background: #eee' : ''
                          "
                          v-bind="attrs"
                          v-on="on"
                        >
                          <div style="display: block">
                            <span
                              style="font-size: 12px"
                              v-if="!notification.isRead"
                              ><strong>{{ notification.title }}</strong></span
                            ><span style="font-size: 12px" v-else>{{
                              notification.title
                            }}</span>
                            <!-- <span
                              style="font-size: 12px"
                              v-if="!notification.isRead"
                            >
                              <strong> {{ notification.message }}</strong>
                            </span>
                            <span style="font-size: 12px" v-else>{{
                              notification.message
                            }}</span> -->
                          </div>
                        </v-list-item>
                        <v-divider></v-divider>
                      </div>
                    </v-list>
                  </template>
                  <v-card>
                    <v-list>
                      <v-list-item>
                        <div style="display: flex">
                          <div>
                            <v-icon style="font-size: 200%">mdi-forum</v-icon>
                          </div>
                          <div class="pl-5">
                            <strong style="font-size: 12px">{{
                              messagetitle
                            }}</strong>
                            <p style="font-size: 12px">{{ message }}</p>
                          </div>
                        </div>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-menu>
            </div>
          </template>
        </div>

        <template>
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <!-- <v-btn color="primary" dark >
                  Dropdown
                </v-btn> -->
                <v-btn text elevation="0" dark v-bind="attrs" v-on="on">
                  <v-icon>mdi-account</v-icon>
                  {{ $store.state.auth.userState.userName }}
                </v-btn>
              </template>
              <v-list>
                <div v-for="(item, index) in buttonItems" :key="index">
                  <v-list-item
                    link
                    v-if="item.isActive"
                    @click="methodsForButtons(item.method, index)"
                    :disabled="computedAccess(item.computed)"
                  >
                    <v-list-item-title>
                      <v-icon>{{ item.icon }} &nbsp; </v-icon>{{ item.title }}
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-app-bar>
      <!-- container -->
      <v-main class="content-box">
        <slot></slot>
      </v-main>
      <Footer />
    </v-app>
    <change-password :dialog="dialog" @disableDialog="dialog = $event" />
    <notification-modal
      :dialog="notificationDialog"
      @reloadHotel="getHotelList()"
      @disableDialog="notificationDialog = $event"
    />
  </div>
</template>

<script>
import AuthService from "../services/AuthService";
import {
  menuList,
  secureStorage,
  actionEnable,
  removeToken,
} from "../utils/helpers";
import Footer from "@/components/Footer.vue";
import ChangePassword from "../components/Modals/ChangePassword.vue";
import NotificationModal from "../components/Modals/NotificationModal.vue";
// import { mapState } from "vuex";
export default {
  name: "MasterLayout",
  components: {
    Footer,
    ChangePassword,
    NotificationModal,
  },
  data: () => ({
    drawer: null,
    dialog: false,
    notificationDialog: false,
    message: "",
    messagetitle: "",
  }),
  // watch: {
  //   notifications: {
  //     handler: function () {

  //   },
  //   deep: true,
  // },
  mounted() {
    console.log();
  },
  computed: {
    changePasswordAccess() {
      return actionEnable("User", "ChangePassword", 1);
    },
    // ...mapState({
    //   notifications: (state) => state.notificationsMessage.notifications,
    // }),
    notifications() {
      if (this.$store.state.notificationsMessage.notifications != 0) {
        return this.$store.state.notificationsMessage.notifications;
      } else {
        let notify = secureStorage().getItem("setMessage");
        return notify;
      }
    },
    checkSendMessage() {
      return actionEnable("Notification", "SendMessage", 1);
    },
    logoutAcces() {
      return true;
    },
    countIsNotRead() {
      const count = this.notifications?.filter((item) => item.isRead === false);
      return count?.length;
    },
    buttonItems() {
      return [
        {
          title: "Change Password",
          icon: "mdi-lock",
          method: "changePassword",
          computed: "changePasswordAccess",
          isActive:
            this.$store.state.auth.userState.roleName !== "Agent"
              ? true
              : false,
        },
        {
          title: "Logout",
          icon: "mdi-account",
          method: "logout",
          computed: "logoutAcces",
          isActive: true,
        },
      ];
    },

    title() {
      return this.$route.meta.title;
    },
    routeLink() {
      return this.$route.path;
    },
    menus() {
      return menuList(
        this.$store.state.auth.userState.actionItems
          ? this.$store.state.auth.userState.actionItems.map((item) => {
              return item.parentSection;
            })
          : ""
      );
    },
  },

  methods: {
    methodsForButtons(method, index) {
      this[method](index);
    },
    async logout() {
      // let payload={connectionId:}
      const response = await AuthService.logout();
      if (!response.data.errorMessage) {
        removeToken();
        // location.reload(true);
        this.$router.push({
          name: "Login",
        });
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.data,
              type: "success",
            },
            { root: true }
          );
          // this.resetForm();

          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
      }
    },
    changePassword() {
      this.dialog = true;
    },
    computedAccess(computedProperty) {
      return !this[computedProperty];
    },
    sendNotification() {
      this.notificationDialog = true;
    },
    readNotification(item) {
      this.messagetitle = item.title;
      this.message = item.message;
      const payload = { attendeeNotificationId: item.id, isRead: true };
      this.$store.dispatch("notificationsMessage/updateNotification", payload);
    },
    messageHover(item) {
      this.messagetitle = item.title;
      this.message = item.message;
    },
  },
};
</script>
