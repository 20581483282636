import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import wb from "./registerServiceWorker";
import "./assets/styles.scss";
import "./vee-validate";
import MasterLayout from "@/Layouts/MasterLayout.vue";
import DefaultLayout from "@/Layouts/DefaultLayout.vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    libraries: "places",
  },
}),
  (Vue.config.productionTip = false);
Vue.prototype.$workbox = wb;
Vue.component("MasterLayout", MasterLayout);
Vue.component("DefaultLayout", DefaultLayout);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
