import EventService from "../../services/EventService";
import { secureStorage } from "../../utils/helpers";
const data = () => ({
  notifications: [],
});

const mutations = {
  setMessage(state, payload) {
    state.notifications = payload;
  },
  updateMessage(state, payload) {
    state.notifications = payload;
  },
};

const actions = {
  getNotificationMessage: ({ commit }) => {
    return new Promise((resolve, reject) => {
      EventService.getNotifications()
        .then((res) => {
          commit("setMessage", res.data.data);
          secureStorage().setItem("setMessage", res.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateNotification: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      EventService.updateNotificationId(payload)
        .then(() => {
          dispatch("getNotificationMessage");
          // commit("updateMessage", res.data.data);
          // return res.data.data;
          // secureStorage().setItem("setMessage", res.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //   removeNotification({ commit }) {
  //     commit("setMessage", { text: "", type: "" });
  //   },
};

const getters = {};

export default {
  state: data,
  getters,
  actions,
  mutations,
  namespaced: true,
};
