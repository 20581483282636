const data = () => ({
  message: {
    text: "",
    type: "",
  },
});

const mutations = {
  setMessage(state, payload) {
    state.message = payload;
  },
};

const actions = {
  setNotification({ commit, dispatch }, payload) {
    if (payload.status !== 401) {
      commit("setMessage", { ...payload });
      setTimeout(() => {
        dispatch("removeNotification");
      }, 5000);
    }
  },
  removeNotification({ commit }) {
    commit("setMessage", { text: "", type: "" });
  },
};

const getters = {};

export default {
  state: data,
  getters,
  actions,
  mutations,
  namespaced: true,
};
