<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <validation-observer ref="modalObserver" v-slot="{ handleSubmit }">
        <v-form
          class="newClass"
          @submit.prevent="handleSubmit(pushNotification)"
          ref="modalForm"
        >
          <v-card-title class="card-title-dialog">
            <span class="text-h6">Send Notification</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="eventId"
                    :items="getActiveEventList"
                    item-value="id"
                    item-text="name"
                    label="Select Event"
                    outlined
                    @change="getEventAttendeesForNotification()"
                    append-icon="mdi-account-box-multiple-outline"
                    hide-details=""
                    :disabled="checkGeteventAttendees"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Attendees Name"
                    rules="required"
                    mode="lazy"
                  >
                    <v-textarea
                      outlined
                      label="Attendees"
                      placeholder="Attendees"
                      v-model="getAttendeesList"
                      disabled
                      :hide-details="!errors.length"
                      :error-messages="errors"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Message Tittle"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      outlined
                      label="Message Tittle"
                      placeholder="Tittle"
                      v-model="title"
                      :hide-details="!errors.length"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Message"
                    :rules="{ required: true }"
                    mode="lazy"
                  >
                    <v-textarea
                      outlined
                      label="Message"
                      placeholder="Message"
                      v-model="notification"
                      :error-messages="errors"
                      counter
                      maxlenght="200"
                      :rules="rule"
                      row-height="3"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
                <!-- <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Gift Name"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Gift Name"
                      class="form-control"
                      placeholder="Gift Name"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-gift"
                      :disabled="loading"
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </validation-provider>
                </v-col> -->
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="$emit('disableDialog', false)"
            >
              Close
            </v-btn>
            <v-btn
              color="add-btn"
              type="submit"
              text
              :disabled="loading || notification.length >= 201"
              :loading="loading"
            >
              Send Notification
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import EventService from "../../services/EventService";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { actionEnable } from "../../utils/helpers/index";
export default {
  //   watch: {
  //     dataReceived: {
  //       handler: function () {
  //         if (this.dataReceived.title === "Update Gift") {
  //           this.id = this.dataReceived.giftId;
  //           this.giftName = this.dataReceived.giftName;
  //           this.virtualFileName = this.dataReceived.giftVirtualFileName;
  //           this.origionalFileName = this.dataReceived.fileName;
  //           this.isActive = this.dataReceived.isActive;
  //           this.preview = this.dataReceived.s3FileUrl;
  //           this.giftImageData = this.dataReceived.s3FileUrl;
  //         }
  //       },
  //       deep: true,
  //     },
  //   },
  async mounted() {
    const getActiveEvent = await EventService.getActiveEvents();
    this.getActiveEventList = getActiveEvent.data.data;
    console.log();
    // this.getEventAttendeesForNotification()
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    getActiveEventList: [],
    loading: false,
    eventId: 0,
    eventName: "",
    notification: "",
    getAttendeesList: [],
    title: "",
    rule: [(val) => val.length <= 200 || "Max 200 characters"],
  }),
  computed: {
    checkGeteventAttendees() {
      return !actionEnable("Notification", "GetEventAttendees", 1);
    },
  },

  methods: {
    async getEventAttendeesForNotification() {
      try {
        this.loading = true;
        // this.eventName=""
        this.eventName = this.getActiveEventList.filter(
          (item) => item.id === this.eventId
        );

        const response = await EventService.getEventAttendeesForNotification(
          this.eventId
        );
        this.getAttendeesList = response.data.data.map((item) => {
          return item.userName;
        });

        if (response.data.errorMessage) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      } catch (error) {
        if (error.toJSON().status === 401) {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Unauthorized",
              type: "error",
            },
            { root: true }
          );
        }
        this.loading = false;
      }
    },
    async pushNotification() {
      const payload = {
        eventId: this.eventId,
        title: this.title,
        message: this.notification,
      };
      const res = await EventService.pushNotificationService(payload);
      if (res.data.errorMessage) {
        this.$store.dispatch(
          "notifications/setNotification",
          {
            text: res.data.errorMessage,
            type: "error",
          },
          { root: true }
        );
        this.$emit("disableDialog", false);
      } else {
        this.$store.dispatch(
          "notifications/setNotification",
          {
            text: "The Message has been sent successfully",
            type: "success",
          },
          { root: true }
        );
      }
      this.resetForm();
      this.$emit("disableDialog", false);
    },
    resetForm() {
      this.$refs.modalForm.reset();
      this.$refs.modalObserver.reset();
      this.eventId = "";
      this.title = "";
      this.getAttendeesList = "";
      this.notification = "";
    },
  },
};
</script>
<style>
/* .modalClass {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
  width: 100%;
} */
/* @media (max-width: 575px) {
  .v-dialog .v-card .v-card__text {
    max-height: inherit;
    height: auto;
  } */
/* } */
</style>
