import http from "./http-client";

class UserService {
  addUser(payload) {
    return http.post("/User/Add", payload);
  }
  getUserDetail(payload) {
    return http.get("/User/Roles", payload);
  }
  updateUser(payload) {
    return http.put("/User/UpdateDetails", payload);
  }
  userList(payload) {
    if (!payload) {
      return http.get(`/User/List`);
    } else {
      return http.get(`/User/List?isActive=${payload}`);
    }
  }
  getRolestype(payload) {
    return http.get("/User/Roles", payload);
  }
  updateUserRole(payload) {
    return http.put("/User/UpdateProfile", payload);
  }
  getAgentList(payload) {
    return http.get("/EventRegisteredReport/UsersList", payload);
  }
  changePassword(payload) {
    return http.post("/User/ChangePassword", payload);
  }
}

export default new UserService();
